import { Row } from 'antd';
import { cloneDeep, isNull, omit, set } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { AUTH_API, resendActivationEmail } from '../../../api/auth';
import { handleApiErrors } from '../../../api/axiosInstance';
import { changeUserStatus, uploadUserProfileImage, USER_API } from '../../../api/user';
import ChangePasswordModal from '../../../components/Modal/ChangePasswordModal';
import Toast from '../../../components/Toast';
import UserAddressFormSection from '../../../components/UserAddressFormSection';
import UserProfileFormSection from '../../../components/UserProfileFormSection';
import { STATUS_LIST } from '../../../enum';
import useFeatureToggle from '../../../hooks/useFeatureToggle';
import useModal from '../../../hooks/useModal';
import { emitFetchTimezones } from '../../../stores/actions/common';
import { formatPageTitle, momentEST } from '../../../utils/common';
import { removeFalseyParams } from '../../../utils/queryParams';
import { hasCompanyAdminRole, hasCompanyManagerOrAdminRole } from '../../../utils/roles';
import { selectStoreGroupWhereMember } from '../../../utils/storeSelectors';
import { hasVerifiedHomeAddress } from '../../../utils/users';
import { canSendActivationLink, canUpdateUserActiveStatus } from './profile-permissions';
import { validateAddress, validateUserProfile } from './profile-validation';

/**
 * User Profile page
 */
const UserDetailsProfileView = props => {
  const { t, myProfile, userDetails, onUserUpdate } = props;

  const { isUserDataReadOnly } = useFeatureToggle();

  const dispatch = useDispatch();
  const [
    isChangePasswordModalVisible,
    openChangePasswordModal,
    closeChangePasswordModal,
  ] = useModal();
  const [isProcessingUpdate, setIsProcessingUpdate] = useState(false);
  const [isSendingActivationLink, setIsSendingActivationLink] = useState(false);
  const { timezones, currentCompany, supportedCountries } = useSelector(state => state.common);
  const companySettings = currentCompany.companySettingId || {};

  useEffect(() => {
    dispatch(emitFetchTimezones());
    // eslint-disable-next-line
  }, []);

  const groupWhereMember = useMemo(() => selectStoreGroupWhereMember(userDetails._id), [
    userDetails,
  ]);

  const changePasswordMutation = useMutation(
    values => AUTH_API.changePassword({ ...values, userId: userDetails?._id }),
    {
      onSuccess: () => {
        closeChangePasswordModal();
        Toast({
          type: 'open',
          message: t('changePasswordSuccess'),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  /**
   * Resend the Activation email to a given user
   *
   * @param {string} email Email of User
   */
  const handleResendActivationEmail = async email => {
    setIsSendingActivationLink(true);
    await resendActivationEmail(email, () => {
      Toast({
        type: 'open',
        message: 'Activation email was sent',
      });
    });
    setIsSendingActivationLink(false);
  };

  /**
   * Handler to update the store and show a Toast message
   * after a successful profile update
   *
   * @param {object} apiResult On Profile Update success
   */
  const onProfileUpdateSuccess = apiResult => {
    const { data } = apiResult.data;
    onUserUpdate(data);

    Toast({
      type: 'open',
      message: 'Profile updated successfully',
    });
  };

  /**
   * Updates the User's profile picture
   *
   * @param {File} profilePicture Image file to upload
   */
  const handleProfileImageUpload = async profilePicture => {
    setIsProcessingUpdate(true);

    try {
      await uploadUserProfileImage(
        { userID: userDetails._id, imageFile: profilePicture },
        result => {
          onProfileUpdateSuccess({
            data: {
              data: {
                ...userDetails,
                profilePicture: result.data.data.location,
              },
            },
          });
        },
        () => {
          Toast({
            type: 'error',
            message: 'Upload failed',
            description: 'We were unable to upload your profile picture',
          });
        },
      );
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('uploadFailed'),
          description: t('profilePictureUploadError'),
        });
      });
    }

    setIsProcessingUpdate(false);
  };

  /**
   * Updates the User's profile
   *
   * @param {object} data Profile data
   */
  const handleProfileUpdate = async formData => {
    if (validateUserProfile(formData)) {
      setIsProcessingUpdate(true);

      try {
        const fieldsToOmit = [];
        if (!hasCompanyAdminRole(myProfile)) {
          fieldsToOmit.push('firstName');
          fieldsToOmit.push('lastName');
          fieldsToOmit.push('employeeId');
          fieldsToOmit.push('directManager');
          fieldsToOmit.push('department');
          fieldsToOmit.push('email');
        } else if (userDetails.email === formData.email) {
          fieldsToOmit.push('email');
        }

        if (
          (!!formData.sendActivationEmailDate || !isNull(formData.sendActivationEmailDate)) &&
          momentEST().isAfter(momentEST(formData.sendActivationEmailDate))
        ) {
          fieldsToOmit.push('sendActivationEmailDate');
        }

        const result = await USER_API.updateUser(
          userDetails._id,
          omit(removeFalseyParams(formData), fieldsToOmit),
        );
        onProfileUpdateSuccess({ data: { data: result } });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: 'Update failed',
            description: 'Error updating profile',
          });
        });
      }

      setIsProcessingUpdate(false);
    }
  };

  const handleUserStatusUpdate = async status => {
    setIsProcessingUpdate(true);

    try {
      const updatedUser = await changeUserStatus(userDetails._id, status);
      onUserUpdate(updatedUser);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: 'Error while updating user status',
        });
      });
    }

    setIsProcessingUpdate(false);
  };

  /**
   * Handler to validate the form data
   * and perform the update API call if the provided data is valid.
   *
   * @param {object} data Address data from Home and Office address forms
   */
  const handleAddressUpdate = async data => {
    const hasData = Object.keys(data).length > 0;

    if (!hasData) {
      Toast({
        type: 'error',
        message: 'You must fill the address form',
      });
    }

    let isValid = false;

    if (data.homeAddress) {
      isValid = data.homeAddress ? validateAddress(data.homeAddress, t('Home Address')) : true;
    } else if (data.officeAddress) {
      isValid = data.officeAddress
        ? validateAddress(data.officeAddress, t('Office Address'))
        : true;
    }

    if (isValid) {
      setIsProcessingUpdate(true);

      try {
        const updatedUser = await USER_API.updateUser(userDetails._id, { personalInfor: data });
        onUserUpdate({ ...userDetails, ...updatedUser });

        Toast({
          type: 'open',
          message: 'Address updated successfully',
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: 'Update failed',
            description: 'Error updating address',
          });
        });
      }

      setIsProcessingUpdate(false);
    }
  };

  const handleAddressConfirm = async () => {
    setIsProcessingUpdate(true);
    try {
      await USER_API.confirmUserHomeAddress(userDetails._id, 'yes');
      const updatedUser = cloneDeep(userDetails);
      set(updatedUser, 'personalInfor.homeAddress.homeAddrUserConf', 'yes');
      onUserUpdate(updatedUser);

      Toast({
        type: 'open',
        message: 'Address confirmed',
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('Error confirming address'),
        });
      });
    }
    setIsProcessingUpdate(false);
  };

  const OFFICE_ADDRESS_SECTION =
    companySettings?.commute?.mainSetting !== 'noOffice' ? (
      <Row gutter={[0, 32]}>
        <UserAddressFormSection
          t={t}
          sectionTitle={t('Office Address')}
          countries={supportedCountries}
          companyAddress={currentCompany && currentCompany.address}
          canEditAddress={
            !isUserDataReadOnly &&
            userDetails.status !== STATUS_LIST.Status.DELETED &&
            (hasCompanyManagerOrAdminRole(myProfile) || myProfile.profile._id === userDetails._id)
          }
          addressInitialValues={userDetails.personalInfor.officeAddress || {}}
          isSubmitting={isProcessingUpdate}
          onSubmit={values => handleAddressUpdate({ officeAddress: values })}
        />
      </Row>
    ) : null;

  const canChangePassword = useMemo(() => {
    return (
      myProfile?.profile?._id === userDetails?._id || (myProfile && hasCompanyAdminRole(myProfile))
    );
  }, [myProfile, userDetails]);

  return (
    <div key={userDetails?._id}>
      <Helmet>
        <title>{formatPageTitle('Profile')}</title>
      </Helmet>

      <Row gutter={[0, 32]}>
        <UserProfileFormSection
          t={t}
          userDetails={userDetails}
          groupDetails={groupWhereMember}
          isSubmitting={isProcessingUpdate}
          isSendingActivationLink={isSendingActivationLink}
          sendActivationLink={handleResendActivationEmail}
          canSendActivationLink={canSendActivationLink(myProfile, userDetails)}
          timezoneOptions={timezones}
          onSubmit={handleProfileUpdate}
          onUserStatusChange={handleUserStatusUpdate}
          onImageUpload={image => handleProfileImageUpload(image)}
          onChangePassword={openChangePasswordModal}
          canChangePassword={canChangePassword}
          canViewDirectManager={!!companySettings?.settings?.detailedReimbursementHistoryCsvFile}
          canChangeDirectManager={hasCompanyAdminRole(myProfile)}
          canEditUserIdentity={hasCompanyAdminRole(myProfile)}
          canUpdateUserActiveStatus={
            canUpdateUserActiveStatus(myProfile) &&
            myProfile.profile._id !== userDetails._id &&
            userDetails?.status !== STATUS_LIST.Status.DELETED
          }
        />
      </Row>

      <br />

      <Row>
        <UserAddressFormSection
          t={t}
          sectionTitle={t('Home Address')}
          countries={supportedCountries}
          hasConfirmedAddress={hasVerifiedHomeAddress(userDetails)}
          onConfirmAddress={handleAddressConfirm}
          companyAddress={currentCompany && currentCompany.address}
          canEditAddress={
            !isUserDataReadOnly &&
            myProfile.profile._id === userDetails._id &&
            userDetails.status !== STATUS_LIST.Status.DELETED
          }
          addressInitialValues={userDetails.personalInfor.homeAddress || {}}
          isSubmitting={isProcessingUpdate}
          onSubmit={values => handleAddressUpdate({ homeAddress: values })}
        />
      </Row>

      <br />

      {OFFICE_ADDRESS_SECTION}

      {canChangePassword && (
        <ChangePasswordModal
          t={t}
          requireCurrentPassword={myProfile?.profile?._id === userDetails?._id}
          visible={isChangePasswordModalVisible}
          onCancel={closeChangePasswordModal}
          onSave={changePasswordMutation.mutateAsync}
          isChangingPassword={changePasswordMutation.isLoading}
        />
      )}
    </div>
  );
};

UserDetailsProfileView.propTypes = {
  t: PropTypes.func.isRequired,
  myProfile: PropTypes.shape({
    profile: PropTypes.shape({
      _id: PropTypes.string,
    }),
  }).isRequired,
  userDetails: PropTypes.shape({
    _id: PropTypes.string,
    personalInfor: PropTypes.shape({
      homeAddress: PropTypes.shape({}),
      officeAddress: PropTypes.shape({}),
    }),
  }).isRequired,
  onUserUpdate: PropTypes.func.isRequired,
};

export default UserDetailsProfileView;
