import { get } from 'lodash';
import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import { momentTimezone } from '../../../utils/common';
import { renderBooleanCheckmarks } from '../../../utils/tables';
import { Button, CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';

const MeasureOneInsuranceItemsTable = props => {
  const { t, dataSource, canSelect, onSelect, isSelecting, onPreviewDocument, ...rest } = props;

  const COLUMNS = useMemo(
    () => [
      {
        hidden: !canSelect,
        title: t('select'),
        render: data => {
          return (
            <Button
              size="xs"
              loading={isSelecting}
              style={{ width: 120, textWrap: 'initial', height: 'max-content' }}
              disabled={
                data?.processingStatus?.toLowerCase() !== STATUS_LIST.Status.COMPLETED ||
                isSelecting ||
                !canSelect ||
                (canSelect && !!data.isSelectedForComplianceCheck)
              }
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onSelect({
                  itemId: data.itemId,
                  coverageItemId: data.coverageItemId,
                });
              }}
            >
              {t(data.isSelectedForComplianceCheck ? 'selected' : 'selectThisPolicy')}
            </Button>
          );
        },
      },
      {
        title: t('status'),
        dataIndex: 'processingStatus',
        render: processingStatus => <StatusTag status={processingStatus} />,
      },
      {
        width: '200px',
        title: t('vehicle'),
        dataIndex: ['details', 'vehicleInfo'],
        render: vehicleInfo =>
          vehicleInfo ? `${vehicleInfo.year} ${vehicleInfo.make} ${vehicleInfo.model}` : '-',
      },
      {
        width: '200px',
        title: t('retrievalReason'),
        dataIndex: 'reasonCodes',
        render: reasonCodes => get(reasonCodes, '[0].description'),
      },
      {
        width: '150px',
        title: t('dateRetrieved'),
        dataIndex: 'createdAt',
        render: date => momentTimezone(date).format('MM-DD-YYYY'),
      },
      {
        width: '150px',
        title: t('lastRefreshed'),
        dataIndex: 'updatedAt',
        render: date => momentTimezone(date).format('MM-DD-YYYY'),
      },
      {
        width: '150px',
        align: 'center',
        title: t('duplicateDocument'),
        ...renderBooleanCheckmarks('isDuplicate'),
      },
      {
        align: 'center',
        title: '',
        render: data => {
          return (
            <Button
              size="xs"
              disabled={!data.itemId}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onPreviewDocument(data.itemId);
              }}
            >
              {t('viewPolicy')}
            </Button>
          );
        },
      },
    ],
    [t, onPreviewDocument, canSelect, isSelecting, onSelect],
  );

  return (
    <CustomTable
      pagination={false}
      {...rest}
      showSearchInput={false}
      rowKey={data => data.createdAt}
      columns={COLUMNS}
      dataSource={dataSource}
      scroll={{ x: 500 }}
    />
  );
};

MeasureOneInsuranceItemsTable.defaultProps = {
  dataSource: [],
};

export default MeasureOneInsuranceItemsTable;
