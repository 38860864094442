import { Row } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { ConfirmModal } from '../..';
import GroupsTable from '../../Table/GroupsTable';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';

const RateDeleteConfirmationModal = props => {
  const {
    t,
    visible,
    rateName,
    affectedGroups,
    defaultRate,
    isDeletingRate,
    onDelete,
    onCancel,
  } = props;

  if (!defaultRate) return null;

  return (
    <ConfirmModal
      loading={isDeletingRate}
      visible={visible}
      title={
        rateName
          ? `Are you sure you want to delete "${rateName}"?`
          : 'Are you sure you want to delete this rate?'
      }
      onOk={onDelete}
      onCancel={onCancel}
      okText="Delete"
      message={
        <div style={{ display: 'block' }}>
          {!!affectedGroups.length && (
            <>
              <Row style={{ marginBottom: 15 }}>
                <Text size="sm" variant="strong" renderAs="p">
                  Number of groups using this rate: {affectedGroups.length}
                </Text>
              </Row>

              <Row style={{ marginBottom: 15 }}>
                <Text size="sm" variant="strong" renderAs="p">
                  If you delete this rate, the group(s) below will be reassigned to your company's
                  default rate:{' '}
                  <Link
                    to={`${INTERNAL_LINKS.RATES}/${defaultRate._id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LinkText variant="strong" size="sm">
                      {defaultRate.name}
                    </LinkText>
                  </Link>
                </Text>
              </Row>

              <Row style={{ marginBottom: 15 }}>
                <GroupsTable
                  t={t}
                  pagination={false}
                  showSearchInput={false}
                  hiddenColumns={['groupManager', 'default', 'users', 'product']}
                  dataSource={affectedGroups.map(groupName => ({
                    name: get(groupName, 'name', groupName),
                  }))}
                />
              </Row>
            </>
          )}

          <Row style={{ overflow: 'auto' }}>
            <Text size="sm" variant="p">
              Note: all previously recorded trips using this rate will continue to use this rate.
            </Text>
          </Row>
        </div>
      }
    />
  );
};

export default RateDeleteConfirmationModal;
