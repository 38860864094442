import { Col, Form, Row, Space } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import {
  deleteInsuranceDocumentImage,
  downloadInsuranceDocumentImage,
} from '../../api/insurance-policies';
import MeasureOneAPI from '../../api/measure-one';
import MeasureOneDataRequestsAPI from '../../api/measure-one-data-requests';
import { USER_API } from '../../api/user';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useModal from '../../hooks/useModal';
import useTablePagination from '../../hooks/useTablePagination';
import { momentFormat, setFullscreenMaxHeight, setModalFullscreenWidth } from '../../utils/common';
import { setupPolicyDocumentsUploadList } from '../../utils/insurancePolicies';
import {
  selectStoreCompanySettings,
  selectStoreCurrentAuthUser,
  useStoreSelector,
} from '../../utils/storeSelectors';
import Button from '../Button';
import GenericModal from '../Modal/GenericModal';
import SpaceSpinner from '../SpaceSpinner';
import MeasureOneInsuranceItemsTable from '../Table/MeasureOneInsuranceItemsTable';
import UploadedFilesTable from '../Table/UploadedFilesTable';
import Text from '../Text';
import Toast from '../Toast';
import MeasureOneInsuranceInfo from './MeasureOneInsuranceInfo';
import MeasureOneLinkSection from './MeasureOneLinkSection';
import SelectInsuranceCountry from './SelectInsuranceCountry';
import UserInsuranceForm from './UserInsuranceForm';

const UserInsuranceSettingFormContent = props => {
  const {
    t,
    insurancePolicy,
    measureOneInsurancePolicy,
    canUpdateMeasureOneInsuranceDoc,
    user,
    isSubmitting,
    onSubmit,
    disabled,
    userComplianceDetails,
    onConfirm,
    ...rest
  } = props;

  const queryClient = useQueryClient();
  const companySettings = useStoreSelector(selectStoreCompanySettings);
  const [documentIdToView, setDocumentIdToView] = useState();
  const [measureOneLinkData, setMeasureOneLinkData] = useState({
    dataRequestId: null,
    publicToken: null,
  });

  const [isInsuranceFromUSA, setIsInsuranceFromUSA] = useState();
  const [isManualInsuranceEnabled, setIsManualInsuranceEnabled] = useState(false);
  const [isSelectingInsuranceCountry, setIsSelectingInsuranceCountry] = useState(false);

  const measureOneProfileMutation = useMutation(
    () => {
      setIsManualInsuranceEnabled(false);
      setIsInsuranceFromUSA(true);
      return new MeasureOneAPI().fetchUserMeasureOnePublicToken();
    },
    {
      onSuccess: data => {
        setMeasureOneLinkData(data);
      },
      onError: error => handleApiErrors(error),
    },
  );

  const updateSelectedInsuranceDocumentMutation = useMutation(
    data => new MeasureOneDataRequestsAPI().updateSelectedInsuranceDocument(data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({
          exact: false,
          queryKey: ['fetchUserMeasureOnePolicyInformation'],
        });

        queryClient.invalidateQueries({
          exact: false,
          queryKey: ['fetchUserFavrComplianceStatuses'],
        });
        Toast({
          type: 'open',
          message: t('updateSelectedInsuranceDocumentSuccess'),
        });
      },
      onError: error => handleApiErrors(error),
    },
  );

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const measureOneDeclarationItemsQuery = useQuery({
    enabled: companySettings.measureOneIntegration,
    queryKey: [
      'fetchUserMeasureOnePolicyInformationItems',
      user._id,
      paginationConfig.current,
      paginationConfig.pageSize,
    ],
    queryFn: () =>
      USER_API.fetchUserMeasureOnePolicyInformationItems(
        user._id,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    onSuccess: ({ totalCount }) => {
      handleTotalUpdate(totalCount);
    },
    onError: () => {
      Toast({
        type: 'error',
        message: t('errorLoadingUserInsurancePolicy'),
      });
    },
  });

  const pdfPreviewQuery = useQuery({
    keepPreviousData: false,
    enabled:
      !!companySettings.measureOneIntegration &&
      !!measureOneInsurancePolicy?.measureOneDataRequestId &&
      !!documentIdToView,
    queryKey: [
      'fetchMeasureOneInsuranceDocumentPreview',
      measureOneInsurancePolicy?.measureOneDataRequestId,
      documentIdToView,
    ],
    queryFn: () =>
      new MeasureOneDataRequestsAPI().fetchMeasureOneInsuranceDocumentPreview(
        measureOneInsurancePolicy?.measureOneDataRequestId,
        documentIdToView,
      ),
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchMeasureOneInsuranceDocumentPreviewError'),
        });
      });
      setDocumentIdToView();
    },
  });

  const [form] = Form.useForm();
  // const [userAddress, setUserAddress] = useState(INITIAL_VALUES);
  const [documentList, setImageList] = useState(
    setupPolicyDocumentsUploadList(insurancePolicy?.policyDocs),
  );
  const [documentsToCreateList, setDocumentsToCreateList] = useState([]);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const currentAuthUser = useSelector(selectStoreCurrentAuthUser);
  const isSameUser = currentAuthUser.profile._id === user._id;

  const [
    isMeasureOneSuccessModalVisible,
    openMeasureOneSuccessModal,
    closeMeasureOneSuccessModal,
  ] = useModal();

  useDidUpdateEffect(() => {
    if (Array.isArray(insurancePolicy?.policyDocs)) {
      const formattedDocs = setupPolicyDocumentsUploadList(insurancePolicy.policyDocs);
      setImageList(formattedDocs);
    }
  }, [insurancePolicy]);

  const handleFileChange = filesInfo => {
    const { file, fileList } = filesInfo;
    const { status } = file;

    if (['removed', 'error'].includes(status)) {
      setIsUploadingFile(false);
    }

    if (status === 'error') {
      setImageList(fileList.filter(item => item.status !== 'error'));
      handleApiErrors(file.response, () => {
        Toast({
          type: 'error',
          message: `Error while uploading ${file.name}`,
        });
      });
    }

    if (['removed', 'uploading'].includes(status)) {
      setImageList(fileList);
    }

    if (status === 'uploading') {
      setIsUploadingFile(true);
    }

    if (status === 'done') {
      setImageList(setupPolicyDocumentsUploadList(file.response.data.policyDocs));
      Toast({
        type: 'open',
        message: `${file.name} uploaded successfully`,
      });

      setIsUploadingFile(false);
    }
  };

  const handleFileToCreateChange = options => {
    const { file, fileList } = options;
    const { status } = file;

    const fileIsInList = documentsToCreateList.find(fl => fl.uid === file.uid);

    if (status === 'uploading' && !fileIsInList) {
      setDocumentsToCreateList(state => {
        return [...state, { ...file, status: 'done' }];
      });
    }

    if (status === 'removed') {
      setDocumentsToCreateList(fileList);
    }
  };

  const handleFileRemove = async file => {
    try {
      const response = await deleteInsuranceDocumentImage(insurancePolicy?._id, file.uid);
      // onUserUpdate({ ...userDetails, personalInfor: response.personalInfor });
      setImageList(setupPolicyDocumentsUploadList(response.policyDocs));
      Toast({
        type: 'open',
        message: `${file.name} deleted successfully`,
      });
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const handleFileDownload = async file => {
    try {
      await downloadInsuranceDocumentImage(insurancePolicy?._id, file.uid, file.name);
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const displayUserInsuranceSection = useMemo(() => {
    if (isManualInsuranceEnabled) return true;
    if (isSelectingInsuranceCountry && typeof isInsuranceFromUSA !== 'boolean') return false;
    if (companySettings.measureOneIntegration && !!insurancePolicy && !measureOneInsurancePolicy)
      return true;

    return !companySettings.measureOneIntegration;
  }, [
    isSelectingInsuranceCountry,
    isInsuranceFromUSA,
    isManualInsuranceEnabled,
    insurancePolicy,
    measureOneInsurancePolicy,
    companySettings.measureOneIntegration,
  ]);

  const displayMOInsuranceSection = useMemo(() => {
    if (isSelectingInsuranceCountry && typeof isInsuranceFromUSA !== 'boolean') return false;
    return !displayUserInsuranceSection && !!companySettings.measureOneIntegration;
  }, [
    isSelectingInsuranceCountry,
    isInsuranceFromUSA,
    displayUserInsuranceSection,
    companySettings,
  ]);

  useEffect(() => {
    if (
      isSelectingInsuranceCountry &&
      !isInsuranceFromUSA &&
      typeof isInsuranceFromUSA === 'boolean'
    ) {
      setIsManualInsuranceEnabled(false);
      setIsSelectingInsuranceCountry(false);
      setIsInsuranceFromUSA();
    }
    // eslint-disable-next-line
  }, [insurancePolicy]);

  if (measureOneLinkData?.publicToken) {
    return (
      <MeasureOneLinkSection
        {...rest}
        {...measureOneLinkData}
        onError={event => {
          setTimeout(() => {
            setMeasureOneLinkData({
              dataRequestId: null,
              publicToken: null,
            });

            switch (event?.type) {
              case 'datasourceNotSupported':
                Toast({
                  type: 'error',
                  message: t('measureOneDatasourceNotSupported'),
                });
                break;
              case 'tokenExpired':
                Toast({
                  type: 'error',
                  message: t('measureOneTokenExpired'),
                });
                break;

              default:
                break;
            }
          }, 2000);
        }}
        onSuccess={() => {
          setTimeout(() => {
            setMeasureOneLinkData({
              dataRequestId: null,
              publicToken: null,
            });
            setIsManualInsuranceEnabled(false);
            setIsSelectingInsuranceCountry(false);
            openMeasureOneSuccessModal();
          }, 2000);
        }}
        onExit={() => {
          setIsInsuranceFromUSA();
          setIsSelectingInsuranceCountry(false);
          setMeasureOneLinkData({
            dataRequestId: null,
            publicToken: null,
          });
        }}
      />
    );
  }

  return (
    <div {...rest}>
      {companySettings.measureOneIntegration &&
        !isSelectingInsuranceCountry &&
        !isManualInsuranceEnabled && (
          <>
            <Row justify="end">
              <Col>
                <Button
                  size="sm"
                  loading={measureOneProfileMutation.isLoading}
                  disabled={disabled || measureOneProfileMutation.isLoading}
                  onClick={() => setIsSelectingInsuranceCountry(true)}
                >
                  {t('uploadInsuranceInformation')}
                </Button>
              </Col>
            </Row>
            <br />
          </>
        )}

      {isSelectingInsuranceCountry && (
        <>
          <Row justify="end">
            <Col>
              <Button
                size="sm"
                type="secondary"
                onClick={() => {
                  setIsInsuranceFromUSA();
                  setIsManualInsuranceEnabled(false);
                  setIsSelectingInsuranceCountry(false);
                }}
              >
                {t('cancelSelection')}
              </Button>
            </Col>
          </Row>
          <br />
        </>
      )}

      {isSelectingInsuranceCountry && !isSubmitting && (
        <SelectInsuranceCountry
          t={t}
          isUSAInsurance={isInsuranceFromUSA}
          onUsaCaClick={measureOneProfileMutation.mutateAsync}
          onOtherCountryClick={() => {
            setIsManualInsuranceEnabled(true);
            setIsInsuranceFromUSA(false);
          }}
        />
      )}

      {displayUserInsuranceSection && (
        <UserInsuranceForm
          t={t}
          disabled={
            disabled || (companySettings.measureOneIntegration && !isManualInsuranceEnabled)
          }
          hideInsuranceDocUpload={
            companySettings.measureOneIntegration && !isManualInsuranceEnabled
          }
          isSubmitting={isSubmitting}
          form={form}
          user={user}
          userComplianceDetails={userComplianceDetails}
          insurancePolicy={insurancePolicy}
          onSubmit={onSubmit}
          isUploadingFile={isUploadingFile}
          documentList={documentList}
          documentsToCreateList={documentsToCreateList}
          onFileChange={handleFileChange}
          onFileToCreateChange={handleFileToCreateChange}
          onConfirmInfoIsUnchanged={onConfirm}
        />
      )}

      {displayMOInsuranceSection && (
        <>
          <MeasureOneInsuranceInfo t={t} insurancePolicy={measureOneInsurancePolicy} />

          <GenericModal
            centered
            footer={null}
            title=" "
            bodyStyle={{ height: '100%' }}
            width={setModalFullscreenWidth(535)}
            visible={isMeasureOneSuccessModalVisible}
            onCancel={closeMeasureOneSuccessModal}
          >
            <Space direction="vertical" size="middle">
              <Text textAlign="center">
                {t('thankYouForProvidingInsuranceInfo__date', {
                  date: momentFormat(new Date(), 'MM/DD/YYYY'),
                })}
              </Text>

              <Text textAlign="center">{t('infoWillDisplayWhenReceivedFromMO')}</Text>
              <br />
            </Space>
          </GenericModal>
        </>
      )}

      {!isSelectingInsuranceCountry && Array.isArray(insurancePolicy?.policyDocs) && (
        <>
          <Text variant="h5" style={{ marginTop: 30 }}>
            {t('insuranceDocumentsHistory')}
          </Text>

          <UploadedFilesTable
            t={t}
            loading={isUploadingFile}
            dataSource={documentList}
            actions={{
              canDownload: true,
              download: handleFileDownload,
              canRemove: isSameUser,
              remove: handleFileRemove,
            }}
          />
        </>
      )}

      {!isSelectingInsuranceCountry &&
        Array.isArray(get(measureOneDeclarationItemsQuery.data, 'documents')) && (
          <>
            <Text variant="h5" style={{ marginTop: 30 }}>
              {t('insuranceDocumentsHistory')}
            </Text>

            <MeasureOneInsuranceItemsTable
              t={t}
              loading={measureOneDeclarationItemsQuery.isFetching}
              dataSource={get(measureOneDeclarationItemsQuery.data, 'documents')}
              canSelect={canUpdateMeasureOneInsuranceDoc}
              onSelect={updateSelectedInsuranceDocumentMutation.mutateAsync}
              onPreviewDocument={setDocumentIdToView}
              isSelecting={updateSelectedInsuranceDocumentMutation.isLoading}
              pagination={{
                pageSize: paginationConfig.pageSize,
                total: paginationConfig.total,
                current: paginationConfig.current,
                onShowSizeChange: handlePageSizeChange,
                hideOnSinglePage: true,
                showSizeChanger: paginationConfig.total > 25,
              }}
              onChange={({ current }) => {
                handlePageChange(current);
              }}
            />

            <GenericModal
              centered
              footer={null}
              bodyStyle={{ height: '100%' }}
              width={setModalFullscreenWidth()}
              height={setFullscreenMaxHeight(1400)}
              title={t('insuranceDocumentPreview')}
              visible={!!documentIdToView}
              onCancel={() => setDocumentIdToView()}
            >
              {pdfPreviewQuery.isFetching && <SpaceSpinner />}

              {pdfPreviewQuery.data && !pdfPreviewQuery.isFetching && documentIdToView && (
                <iframe
                  height="99%"
                  width="100%"
                  title="insurance document"
                  src={pdfPreviewQuery.data}
                  onLoad={() => {
                    if (pdfPreviewQuery.data) {
                      URL.revokeObjectURL(pdfPreviewQuery.data);
                    }
                  }}
                />
              )}
            </GenericModal>
          </>
        )}
    </div>
  );
};

export default withNamespaces()(UserInsuranceSettingFormContent);
