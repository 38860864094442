import React from 'react';

import DisableAchFeaturesConfirmationModal from '../../../components/Modal/DisableAchFeaturesConfirmationModal';
import useModal from '../../../hooks/useModal';
import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const ReimbursementSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange, disabled } = props;

  const [
    isAchConfirmationModalOpen,
    openAchConfirmationModal,
    closeAchConfirmationModal,
  ] = useModal();

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('Reimbursement_plural')}>
      <FeatureFormItem
        label={t('detailedReimbursementHistoryCsvFile')}
        value={companySettings.detailedReimbursementHistoryCsvFile}
        onChange={checked => onFeatureChange({ detailedReimbursementHistoryCsvFile: checked })}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('doNotShowReceiptInUI')}
        value={companySettings.hideReceipts}
        onChange={checked => onFeatureChange({ hideReceipts: checked })}
        helpText={t('doNotShowReceiptInUI_SysAdminHelpText')}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('customPayrollFile')}
        value={companySettings.customPayrollFileConfiguration}
        onChange={checked => onFeatureChange({ customPayrollFileConfiguration: checked })}
      />

      <FeatureFormItem
        label={t('downloadPayrollFile')}
        value={companySettings.downloadPayrollFile}
        onChange={checked => onFeatureChange({ downloadPayrollFile: checked })}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('enableAch')}
        disabled={disabled}
        value={companySettings.ach_enabled}
        onChange={ach_enabled => {
          onFeatureChange({ ach_enabled });

          if (!ach_enabled) {
            openAchConfirmationModal();
          }
        }}
      />

      <FeatureFormItem
        label={t('simulateFailedPayrollFileUploadToS3')}
        value={companySettings.simulateFailedPayrollFileUploadToS3}
        onChange={checked => onFeatureChange({ simulateFailedPayrollFileUploadToS3: checked })}
      />

      <FeatureFormItem
        label={t('simulateFailedPayrollFileUploadToSftp')}
        value={companySettings.simulateFailedPayrollFileUploadToSftp}
        onChange={checked => onFeatureChange({ simulateFailedPayrollFileUploadToSftp: checked })}
      />

      <DisableAchFeaturesConfirmationModal
        t={t}
        visible={isAchConfirmationModalOpen}
        onCancel={() => {
          onFeatureChange({ ach_enabled: true });
          closeAchConfirmationModal();
        }}
        onConfirm={closeAchConfirmationModal}
      />
    </SettingSectionWrapper>
  );
};

export default ReimbursementSettingsFormItems;
